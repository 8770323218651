


const GridCard = () => {
    return (
        <div className="bg-[#5b19c4] py-12 px-12 my-12">
            <div className="container mx-auto max-w-[1180px]">
                <div className="text-center">
                    <h2 className="text-4xl font-bold mb-10 text-[#00c9cf] text-center">З чим може допомогти ментор</h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 divide-y-2 md:divide-y-0 md:divide-x-2 divide-[#00c9cf] md:mb-6">
                    <div className="text-center px-8 py-10">
                        <p className="text-[16px] leading-[24px] font-medium text-base text-white">Визначитися із професією або віднайти більш вузьку нішеву спеціалізацію</p>
                    </div>

                    <div className="text-center px-8 py-10">
                        <p className=" text-[16px] leading-[24px]  font-medium text-base text-white">Оцінити власний досвід, таланти, вміння</p>
                    </div>

                    <div className="text-center px-8 py-10">
                        <p className=" text-[16px] leading-[24px]  font-medium text-base text-white">Зрозуміти, які навички потрібно здобути для тієї чи іншої посади</p>
                    </div>
                </div>
                <hr className="h-[2px] w-full bg-[#00c9cf]" />
                <div className="grid grid-cols-1 md:grid-cols-3 divide-y-2 md:divide-y-0 md:divide-x-2 divide-[#00c9cf] md:mt-6">
                    <div className="text-center px-8 py-10">
                        <p className=" text-[16px] leading-[24px]  font-medium text-base text-white">З’ясувати, які перешкоди
                            не дозволяють отримати
                            омріяну роботу</p>
                    </div>

                    <div className="text-center px-8 py-10">
                        <p className=" text-[16px] leading-[24px]  font-medium text-base text-white">Дізнатися більше про індустрію,
                            в якій плануєте працювати</p>
                    </div>

                    <div className="text-center px-8 py-10">
                        <p className=" text-[16px] leading-[24px]  font-medium text-base text-white">Довідатися, як правильно шукати вакансії, складати резюме та проходити співбесіди</p>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default GridCard;