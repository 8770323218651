import React from "react";
import JustTextBlock from "../../components/JustTextBlock";
import Main from '../../components/Main';
import PhotoTextBlock from "../../components/elements/PhotoTextBlock";
import GridCard from "../../components/mentors/GridCaard";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import parse from 'html-react-parser';

const MeetingSteps = () => {
    return (
        <div className="bg-white rounded-lg md:flex items-center">
            <h2 className="text-[36px] text-[#262626] font-bold mb-4 w-full md:w-1/2 md:text-right md:pr-[50px] md:py-10">Як назначити<br /> зустріч з ментором</h2>
            <div className="space-y-3 w-full md:w-1/2 md:pl-[50px] md:border-l-2 md:border-[#00c9cf] py-10">
                <div className="flex items-baseline">
                    <div className="text-[36px] text-[#00c9cf] font-bold mr-4 w-[24px]">1</div>
                    <div className="text-[#262626] font-medium text-[16px] leading-[24px]">Вибрати ментора з переліку</div>
                </div>
                <div className="flex items-baseline">
                    <div className="text-[36px] text-[#00c9cf] font-bold mr-4 w-[24px]">2</div>
                    <div className="text-[#262626] font-medium text-[16px] leading-[24px]">Обрати дату та час зустрічі в Zoom, Teams, Google Meets</div>
                </div>
                <div className="flex items-baseline">
                    <div className="text-[36px] text-[#00c9cf] font-bold mr-4 w-[24px]">3</div>
                    <div className="text-[#262626] font-medium text-[16px] leading-[24px]">Надіслати заявку з описом конкретного питання</div>
                </div>
                <div className="flex items-baseline">
                    <div className="text-[36px] text-[#00c9cf] font-bold mr-4 w-[24px]">4</div>
                    <div className="text-[#262626] font-medium text-[16px] leading-[24px]">Використати годину зустрічі з максимальною ефективністю</div>
                </div>
            </div>
        </div>
    );
};

const EmployeeCard = (props) => {
    const [open, setOpen] = React.useState(false);
    const { name, title, company, imageUrl, email } = props;
    return (<div>
        <div className="overflow-hidden">
            <div className="bgHover cursor-pointer" onClick={() => setOpen(!open)}>
                <img className="w-full" src={imageUrl} alt={name} />
                <div className="py-4 h-[140px]">
                    <div className="font-bold text-[24px] text-[#262626] whitespace-pre-wrap leading-none mb-2">{name.split(' ').join('\n')}</div>
                    <p className="text-[18px] text-[#262626] font-medium leading-none">
                        {title}
                    </p>
                    <p className="text-[18px] text-[#262626] font-medium leading-none">
                        {company}
                    </p>
                </div>
            </div>
            <div className="pt-4 pb-2">
                <a href={`mailto:${email}`} className="text-[#00c9cf] text-[18px] underline font-bold py-2">
                    Надіслати заявку
                </a>
            </div>
        </div>
        <Modal open={open} onClose={() => setOpen(false)} center className="z-[99999]">
            <div className="p-0 md:p-4 lg:p-8">
                <div className="flex flex-col md:flex-row mb-4">
                    <img className="object-cover w-full max-w-[250px] h-auto max-h-[250px] self-center md:self-auto md:mr-[30px]" src={imageUrl} alt={name} />
                    <div className="flex flex-col">
                        <h2 className="text-[24px] leading-[30px] font-bold text-[#262626] ">{name}</h2>
                        <p className="text-[18px] text-[#262626] font-medium leading-[30px]">
                            {title}
                        </p>
                        <p className="text-[18px] text-[#262626] font-medium leading-[30px]">
                            {company}
                        </p>
                    </div>
                </div>
                <div className="text-[16px] font-medium text-[#262626] leading-[24px] mb-6">
                    {parse(props.content)}
                </div>
            </div>
        </Modal>
    </div>
    );
};


const Mentors = () => {
    const employees = [
        {
            id: 1,
            name: 'Наталія Стаценко',
            email: 'natalia.statsenko@dentsu.com.ua', // Add the correct email
            title: 'HR Director',
            company: 'dentsu Ukraine',
            imageUrl: '/media/employees/n-statsenko.jpg', // Update with the correct path to the image
            content: `<p>Наталія працює в HR сфері з 2009 року, має досвід роботи в міжнародних медіа, FMCG та логістичних компаніях. З 2017 року обіймає посаду HR директора dentsu Ukraine. Ключова експертиза охоплює управління залученістю та продуктивністю персоналу, розвиток бренду роботодавця, управління та розвиток талантів, рекрутинг.</p>
            <br/><p><b>Можу бути ментором</b> у питаннях кар’єрної консультації, розвитку менеджерських компетенцій, плану розвитку в професії, розвитку hard і soft навичок.</p>`
        },
        {
            id: 2,
            name: 'Ірина Балашова',
            email: 'iryna.balashova@iprospect.com.ua', // Add the correct email
            title: 'CEO',
            company: 'iProspect Ukraine',
            imageUrl: '/media/employees/i-balashova.jpg', // Update with the correct path to the image
            content: `<p>З 2014 року працює в маркетингових агентствах. Починала кар'єру проєктним менеджером в Українській асоціації директ-маркетингу, де займалася організацією досліджень ринків поштової логістики та e-commerce. Керувала проектами в сфері inbaund- та email-маркетингу, брала участь в розробці та просуванні маркетингових стратегій клієнтів в агентстві маркетингових комунікацій OSDirect. З моменту заснування iProspect Ukraine займала позицію Client Service Director. А в 2021 році взяла на себе роль CEO агентства. </p>
            <br/><p><b>Можу бути ментором</b> у питаннях міжособистісних комунікацій, клієнтського сервісу, диджитал маркетингу та проходження співбесід.</p>`
        },
        {
            id: 3,
            name: 'Тетяна Юрченко',
            email: 'tetiana.yurchenko@dentsu.com.ua', // Add the correct email
            title: 'Communications Director',
            company: 'dentsu Ukraine',
            imageUrl: '/media/employees/t-yurchenko.jpg', // Update with the correct path to the image
            content: `<p>Спеціаліст у сфері медіа та корпоративних комунікацій, має 10-річний досвід роботи в PR-департаментах крупних медіакомпаній України. Майже 3 роки очолювала прес-службу телеканалу ICTV, працювала у медіакомпанії Burda Ukraine (2014), на телеканалі «Інтер» (2012), а також у сфері споживчого маркетингу в мережевому комунікаційному агентстві SPN Communications. У 2015 отримала ступінь кандидата наук із соціальних комунікацій (КНУ ім. Тараса Шевченка). З 2017 року обіймає посаду комунікаційного директора dentsu Ukraine. </p>
            <br/><p><b>Можу бути ментором</b> у питаннях побудови персонального бренду, client service комунікацій, міжособистісних комунікацій, профорієнтації та роботи з професійними страхами. </p>`
        },
        {
            id: 4,
            name: "Віталій Кулько",
            email: 'vitaliy.kulko@isobar.com.ua', // Add the correct email
            title: 'Digital Innovation Coach',
            company: 'Isobar Ukraine',
            imageUrl: '/media/employees/v-kulko.jpg', // Update with the correct path or a placeholder image
            content: `<p>Віталій розпочав кар'єру в рекламній та креативній сфері у 2004 році, займаючись підтримкою клієнтів в процесі розробки рекламних кампаній та проектів. З 2010 року працює в агентстві Isobar Ukraine. Пройшов шлях від Production менеджера до позиції Digital Innovation Coach, сформував у агентстві підрозділ з розробки digital контенту. Експертиза Віталія полягає у розвитку інноваційних рішень для клієнтів. У 2015 році заснував ініціативу стажування та працевлаштування глухих та слабочуючих людей у сфері реклами та IT, яка отримала нагороди міжнародних організацій з прав людини та доступних можливостей роботи для людей з інвалідністю. </p>
            <br/><p><b>Можу бути ментором</b> у питаннях переходу в технічну сферу роботи, ефективного менеджменту команди веб-розробки, створення резюме та LinkedIn-сторінки диджитал-фахівця, створення інклюзивного робочого середовища та навчального процесу для людей з інвалідністю, створення інноваційних проєктів та рішень, генерації ідей з використанням підходів “дизайн мислення”. </p>`
        },
        {
            id: 5,
            name: "Ольга Соколова",
            email: 'olha.sokolova@dentsu.com.ua', // Add the correct email
            title: 'Head of Talent Development & Communications',
            company: 'dentsu Ukraine',
            imageUrl: '/media/employees/o-sokolova.jpg', // Update with the correct path or a placeholder image
            content: `<p>Ольга 7 років працює в HR-сфері, де реалізує стратегії комунікацій та розвитку для колег. Кар’єрний шлях розпочався з ролі проджект менеджера у глобальній трансформації HR-функції в Nestlé, де Ольга відповідала за всі локальні комунікації з проєкту та навчання/адаптацію колег до нових HR-процесів. Близько року була менеджером з комунікацій в Borjomi Ukraine. З 2021 року розвиває внутрішні корпоративні комунікації в dentsu Ukraine, а з початку 2022 лідує напрямок розвитку талантів. </p>
            <br/><p><b>Можу бути ментором</b> у питаннях формування індивідуального плану розвитку, ведення міжособистісних комунікацій в корпоративному житті та процесу підготовки до співбесіди. </p>`
        },
        {
            id: 6,
            name: "Олександра Максименко",
            email: 'aleksandra.maksymenko@dentsu.com.ua', // Add the correct email
            title: 'Talent Acquisition Lead',
            company: 'dentsu Ukraine',
            imageUrl: '/media/employees/o-maksimenko.jpg', // Update with the correct path or a placeholder image
            content: `<p>З 2015 року працює у сфері HR і рекрутингу. За цей час закрила більше 1000 вакансій різного рівня складності. В холдингу dentsu Ukraine почала працювати з 2021 року і за цей час виросла з позиції рекрутера до керівника групи з підбору персоналу, поглибивши свої знання в рекламній і маркетинговій сфері. </p>
            <br/><p><b>Можу бути ментором</b> у питаннях формування CV і супровідного листа, кар'єрної консультації, підготовці до проходження співбесіди, аналітики ринку праці і заробітних плат в Україні, виборі майбутнього роботодавця.</p>`
        },
        // Add more employees as needed
    ];

    return (
        <div className="business-practices">
            <Main
                title="Менторство"
                subtitle="Допомагає віднайти вектор
                вашого власного професійного зростання"
                backgroundImage="/media/mentors_bg.jpg"
            />

            <JustTextBlock
                title='З чого почати'
                content={`<p className="text-[20px] font-medium text-[#262626] mb-6 leading-[30px]">
                Якщо ви ще не визначилися із подальшими кар’єрними кроками або сумніваєтеся, яка саме сфера в рекламі чи який напрямок вам буде до душі, ми пропонуємо пройти сесію із ментором.
                </p>`} />

            <PhotoTextBlock
                img={'/media/mentros_2.jpg'}
                title='Хто такий ментор'
                content={`<p className="text-base font-medium text-[#262626] mb-6">
                Ментор – це просто ваш друг, який більш досвідчений в конкретній професії і може поділитися з вами прикладними знаннями у форматі живої невимушеної розмови.
<br/><br/>
Відповідно до сфери менторів можна розділити за компетенцією у певній сфері: ІТ, маркетинг, реклама, креативні індустрії тощо. Також є ментори, які допоможуть в цілому оцінити ваші навички і спрямувати щодо подальших кроків у працевлашутванні.
                </p>`} />

            <GridCard />

            <div className="container mx-auto px-4 mb-12">
                <MeetingSteps />
            </div>

            <div className="bg-[#e8e8e8] p-8">
                <div className="container mx-auto max-w-[1180px] px-4 mb-12">

                    <h1 className="text-[36px] font-bold text-[#262626] mb-12">Наші ментори</h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                        {employees.map((employee) => (
                            <EmployeeCard key={employee.id} {...employee} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Mentors;