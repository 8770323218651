import React, { useState } from 'react';
import { fallDown as Menu } from 'react-burger-menu'
import LanguageSwitcher from './LanguageSwitcher';
import Navbar from './Navbar';

export default function Header() {
  const [isOpen, setOpen] = useState(false);

  return (
    <header className={`z-[9999] bg-black ${isOpen ? 'fixed w-full' : 'static w-auto'}`} >
      <div className='container static mx-auto max-w-[1180px] flex justify-between items-center' id="header">
        <div className="header__logo">
          <a href='/'><img src='https://static.dentsu.com.ua/assets/logos/main-logo.png' alt='Dentsu Ukraine' /></a>
        </div>

        {/* <div className="language-switcher mr-[75px] right-0">
          <LanguageSwitcher />
        </div> */}
        <Navbar isOpen={isOpen} setOpen={setOpen} />
      </div>
    </header>
  );
}
