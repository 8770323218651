import { Link } from "react-router-dom";
import { ServicesCard } from "./elements/CardWithImg";

const ServicesSection = () => {
    return (
        <div className="container mx-auto" id="businesses">
            <div className='max-w-[1180px] mx-auto'>

                <div className="py-12 bg-white text-center">
                    <h2 className="text-4xl font-bold mb-10 text-[#262626] ">Бізнесам</h2>
                    <div className="flex flex-col space-y-8 md:space-y-0 md:flex-row justify-center md:space-x-4">
                        {/* Карточка услуги */}

                        <Link to="/marketing" className="bgHover w-full md:w-1/3 px-4 flex flex-col">
                            <ServicesCard link={'/marketing'} title="Ветеранський маркетинг"
                                description="Рекомендації щодо адаптації брендових комунікацій до цінностей ветераноцентричного суспільства" image="/media/businesses/businesses_2.jpg" />
                        </Link>


                        <Link to="/business-practices" className="bgHover w-full md:w-1/3 px-4 flex flex-col">
                            <ServicesCard link={'/business-practices'} title="Обмін досвідом"
                                description="Публікація успішних кейсів
                            з ветеранських ініціатив
                            у різних сферах бізнесу " image="/media/businesses/businesses_3.jpg" />
                        </Link>

                        <Link to="#" className="bgHover w-full md:w-1/3 px-4 flex flex-col">
                            <ServicesCard title="Консалтинг"
                                description="Освітні заходи для спільноти роботодавців, які розробляють програми для ветеранів та ветеранок" image="/media/businesses/businesses_1.jpg" />
                        </Link>


                        {/* Карточка услуги */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesSection;
