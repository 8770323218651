import React from 'react';
import Slider from 'react-slick';
import { ReactComponent as Quotes } from '../components/icons/quote.svg';

const Quote = ({ children }) => (
    <div className="relative text-[#6beef1] text-xl mb-4 italic flex flex-col md:flex-row">
        <span className=" top-0 left-0"><Quotes /></span>
        <div>{children}</div>
        <span className=" bottom-0 right-0 self-end"><Quotes className='rotate-180 ' /></span>
    </div>
);

const Card = ({ image, persona, position, quote }) => {

    return (
        <div className="flex flex-col items-center justify-center px-4">
            <img
                src={image} // Укажите путь к изображению
                alt={persona}
                className="rounded-full mb-4 w-52 h-52 object-cover mx-auto"
            />
            <Quote>
                {quote}
            </Quote>
            <p className="text-[#6beef1] font-bold text-lg">{persona}</p>
            <p className="text-[#6beef1] text-lg">{position}</p>
        </div>
    );
};

const TestimonialSlider = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        customPaging: i => (
            <div className=" h-[2px] w-full bg-white bg-opacity-50"></div> // Тут вы можете настроить внешний вид табов
        )
    };

    return (
        <div className="bg-[#5b19c3] px-6 py-16 text-center">
            <div className='container mx-auto max-w-4xl w-auto'>
                <Slider {...settings}>
                    {/* Слайд */}
                    <Card
                        image="/media/persona/Hue_Saturation 4.jpg"
                        persona="Юрченко Тетяна"
                        position="Комунікаційна директорка, dentsu Ukraine"
                        quote="Ветеранська політика — це не про одноразові пільги й субсидії, це про сталий розвиток та інклюзивні підходи у всіх сферах життя на довгостроковій стратегічній основі."
                    />

                    <Card
                        image="/media/persona/N_S.jpg"
                        persona="Наташа Стаценко"
                        position="HR Director, dentsu Ukraine"
                        quote="Підтримка ветеранів і їх сімей в навчанні та працевлаштуванні – це не корпоративний тренд, а необхідність, яку мають усвідомлювати українські компанії. Окрім розвитку бренду роботодавця за цим стоїть і прийняття наших реалій та адаптація під талановитих кандидатів сьогодення."
                    />

                    <Card
                        image="/media/persona/O_S.jpg"
                        persona="Ольга Соколова"
                        position="Head of Talent Development & Communications, dentsu Ukraine"
                        quote="Ми маємо розуміти, що все більше ветеранів повертатимуться до цивільного корпоративного життя. Освітні програми з перекваліфікації – частина тієї вдячності, яку ми як соціально відповідальна компанія можемо виразити їм за захист наших життів."
                    />

                    <Card
                        image="/media/employees/i-balashova.jpg"
                        persona="Ірина Балашова"
                        position="CEO, iProspect Ukraine"
                        quote="Основна цінність iProspect – це знання, експертиза і досвід спеціалістів, які в нас працюють. Всім цим ми хочемо і вміємо ділитись. У проекті бачу можливість повернути відчуття корисності тим, хто через війну втратив професію чи попереднє місце роботи. В диджиталі роботи вистачить усім, аби лише було бажання освоювати нове!"
                    />
                </Slider>
            </div>
        </div>
    );
};

export default TestimonialSlider;
