import parse from 'html-react-parser';

const JustTextBlock = ({
    title = '',
    content = ` <p className="text-[20px] font-medium text-[#262626] mb-6 leading-[30px]">
    Ми розуміємо, що кожен відповідальний бізнес повинен стати компонентом ветеранської політики,
    створюючи освітні можливості, нові робочі місця, а головне – безпечний і комфортний
    простір для захисників України, які повертаються до цивільного життя.
</p>`
}) => {
    return (
        <div className="container mx-auto py-16 px-4">
            <div className='max-w-[1180px] mx-auto'>
                {title && <h2 className="text-4xl font-bold mb-4 text-[#262626]">{title}</h2>}
                <div className=''>
                    {parse(content)}
                </div>
            </div>
        </div>
    );
};

export default JustTextBlock;
