import React, { useEffect, useState } from 'react';
import Hamburger from 'hamburger-react';

const Navbar = ({ isOpen, setOpen }) => {
    const [submenuOpen, setSubmenuOpen] = useState(false);

    const menuStyles = {
        transition: 'transform 0.3s ease, opacity 0.3s ease',
        transform: isOpen ? 'translateY(0)' : 'translateY(-100%)',
        opacity: isOpen ? '1' : '0',
        pointerEvents: isOpen ? 'auto' : 'none', // Prevents click events when hidden
    };
    
    // hidde body scroll when menu is open
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';

        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isOpen]);

    return (
        <>
            <div className='right-[10px] lg:right-[36px]'
                style={{
                    position: 'fixed',
                    width: 'auto',
                    height: 'auto',
                    top: '10px',
                    zIndex: '9999',
                }}>
                <Hamburger toggled={isOpen} toggle={setOpen} direction="right" color="#fff" />
            </div>
            <div
                style={menuStyles}
                className="fixed top-[69px] right-0 bottom-0 overflow-auto bg-black w-full h-screen z-[9999] transition-all duration-300"
            >

                <ul className="container mx-auto auto max-w-[1180px] header-slider-menu">
                    <li className="header-slider-menu-link" onClick={() => setSubmenuOpen(!submenuOpen)}>
                        <h1 className="header-slider-menu-link__active font-bold flex">Хто ми
                            <svg className="arrow-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                            </svg>
                        </h1>
                        <ul className={`overflow-hidden transition-height duration-300 ease-in-out ${submenuOpen ? 'max-h-40' : 'max-h-0'}`}>
                            <li><a href="https://dentsu.com.ua/about/">Dentsu Ukraine</a></li>
                            <li><a href="https://dentsu.com.ua/leadership/">Агентства</a></li>
                            <li><a href="https://dentsu.com.ua/team/">Команда</a></li>
                        </ul>
                    </li>
                    <li className="header-slider-menu-link">
                        <h1 className="header-slider-menu-link__active flex">
                            <a href="https://dentsu.com.ua/news/">Новини</a>
                        </h1>
                    </li>
                    <li className="header-slider-menu-link">
                        <h1 className="header-slider-menu-link__active flex">
                            <a href="https://futurelab.dentsu.com.ua/">Future Lab</a>
                        </h1>
                    </li>
                    <li className="header-slider-menu-link">
                        <h1 className="header-slider-menu-link__active flex">
                            <a href="https://dentsu.com.ua/csr/">Відповідальне лідерство</a>
                        </h1>
                    </li>
                    <li className="header-slider-menu-link">
                        <h1 className="header-slider-menu-link__active flex">
                            <a href="https://veterans.dentsu.com.ua/">Veterans Up</a>
                        </h1>
                    </li>
                    <li className="header-slider-menu-link">
                        <h1 className="header-slider-menu-link__active flex">
                            <a href="https://dentsu.com.ua/careers/">Кар'єра</a>
                        </h1>
                    </li>
                    <li className="header-slider-menu-link">
                        <h1 className="header-slider-menu-link__active flex">
                            <a href="https://dentsu.com.ua/contacts/">Контакти</a>
                        </h1>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Navbar;
