import '../../App.css';
import Main from '../components/Main';
import InfoBlock from '../components/InfoBlock';
import TestimonialSlider from '../components/TestimonialSlider';
import AboutUsSection from '../components/AboutUsSection';
import VeteransSection from '../components/VeteransSection';
import ServicesSection from '../components/ServicesSection';
import TextBlockComponent from '../components/TextBlockComponent';
import BusinessPracticesSlider from '../components/BusinessPracticesSlider';


function MainPage() {

    return (<div>
        {/* <Header /> */}

        <Main />

        <InfoBlock />

        <TextBlockComponent
            text='Наша ціль – ініціювати змінотворчі процеси в суспільстві на прикладі комунікаційної сфери, де зусилля маленьких спільнот можуть стати основою для державних перетворень.'
        />

        <AboutUsSection />

        <TestimonialSlider />

        <VeteransSection />

        <TextBlockComponent
            text='Різноманітність досвіду, зокрема цивільного і бойового, створює нові можливості для корпоративного сектору.'
        />

        <ServicesSection />

        <BusinessPracticesSlider />

        {/* <Footer /> */}
    </div>);
}

export default MainPage;
