

const InformationBlock = () => {
  return (
    <div className="bg-[#e8e8e8] py-12 px-12 my-12">
      <div className="container mx-auto max-w-[1180px]">
        <div className="grid grid-cols-1 md:grid-cols-3 divide-y-2 md:divide-y-0 md:divide-x-2 divide-[#00c9cf] mb-12">
          <div className="text-center px-8 py-4">
            <h3 className="text-2xl font-bold text-[#262626] mb-4">Тривалість<br/> курсу</h3>
            <p className="font-medium text-base text-[#262626]">2 місяці</p>
          </div>

          <div className="text-center px-8 py-4">
            <h3 className="text-2xl font-bold text-[#262626] mb-4">Умови<br/> участі</h3>
            <p className="font-medium text-base text-[#262626]">Безкоштовно за результатами<br/> відбору</p>
          </div>

          <div className="text-center px-8 py-4">
            <h3 className="text-2xl font-bold text-[#262626] mb-4">Як долучитися<br/> до проєкту</h3>
            <p className="font-medium text-base text-[#262626]">Надіслати резюме та<br/> поспілкуватися з організаторами</p>
          </div>

        </div>
        <div className="text-center">
          <a href="https://forms.office.com/e/Y1fuvYH9wN" className="bg-[#00c9cf] font-bold text-[#262626] text-white py-4 px-10 mr-4 hover:bg-opacity-80 duration-200 " >Надіслати резюме</a>
        </div>
      </div>
    </div >
  );
};

export default InformationBlock;
