const AboutUsSection = () => {
    return (
      <div className="flex flex-col md:flex-row ">
        <div className="w-full md:w-[55%]">
          <img
            src="/media/2_1.jpg" // Укажите путь к вашему изображению здания
            alt="Building"
            className="w-full object-cover h-full"
          />
        </div>
        <div className="w-full md:w-[35%] max-w-[500px] px-[50px] py-[100px] flex flex-col items-baseline justify-center">
          <h2 className="text-4xl font-bold mb-4 text-[#262626]">Хто ми</h2>
          <p className=" mb-8 w-full text-xl text-[#262626] leading-[30px] font-medium">
          dentsu Ukraine – найбільша комунікаційна група в Україні, представник одного з найкрупніших холдингів у світі. 
          В компанії працює понад 400 співробітників, забезпечуючи сервіс для найуспішніших українських і 
          міжнародних брендів. 
          </p>
          <a href="https://dentsu.com.ua/about/" className="bg-[#00c9cf] text-white py-4 px-6 font-bold">Дізнатися більше</a>
        </div>
      </div>
    );
  };
  
  export default AboutUsSection;
  