import MainPage from "../pages/MainPage";
import VeteransMarketing from "../pages/ua/VeteransMarketing";
import VeteranUp from "../pages/ua/VeteranUp";
import BusinessPractices from "../pages/ua/BusinessPractices";
import AddCase from "../pages/ua/AddCase";
import Mentors from "../pages/ua/Mentors";
import Articles from "../pages/ua/Articles";


export const pages = {
    ua: {

        articles: {
            title: 'Статті',
            path: '/news/:url',
            Component: Articles
        },
        main: {
            title: 'Головна',
            path: '/',
            Component: MainPage
        },
        marketing: {
            title: 'Маркетинг',
            path: '/marketing',
            Component: VeteransMarketing
        },
        veteranUp: {
            title: 'VeteranUp',
            path: '/veteransup',
            Component: VeteranUp
        },
        businessPractices: {
            title: 'BusinessPractices',
            path: '/business-practices',
            Component: BusinessPractices
        },
        // AddCase
        addCase: {
            title: 'Додати кейс',
            path: '/add-case',
            Component: AddCase
        },
        // Mentors
        mentors: {
            title: 'Ментори',
            path: '/mentors',
            Component: Mentors
        },

    },
    en: {
        main: {
            title: 'Головна',
            path: '/en/',
            Component: MainPage

        },
        marketing: {
            title: 'Маркетинг',
            path: '/en/marketing',
            Component: VeteransMarketing

        },
    }
}