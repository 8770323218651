import { Link } from "react-router-dom";
import { ServicesCard } from "./elements/CardWithImg";

const VeteransSection = () => {
    return (
        <div className="bg-gray-200" id="veterans">
            <div className='container mx-auto max-w-[1180px]'>
                <div className="py-12 text-center">
                    <h2 className="text-4xl font-bold mb-10 text-[#262626]">Учасникам</h2>
                    <div className="flex flex-col space-y-8 md:space-y-0 md:flex-row justify-center md:space-x-4">
                        {/* Карточка услуги */}
                        <Link to="/veteransup" className="bgHover w-full md:w-1/3 px-4 flex flex-col">
                            <ServicesCard
                            link='/veteransup'
                                title="Навчання"
                                description="Інтенсивний освітній курс за низкою спеціальностей – PPC, SEO, Account менеджмент"
                                image="/media/veterans/veterans_1.jpg" />
                        </Link>

                        <Link to="/mentors" className="bgHover w-full md:w-1/3 px-4 flex flex-col">
                            <ServicesCard
                            link={'/mentors'}
                                title="Менторство"
                                description="Консультування щодо персонального кар’єрного плану у сферах реклами, маркетингу, комунікацій"
                                image="/media/veterans/veterans_2.jpg" />
                        </Link>

                        <Link to="#" className="bgHover w-full md:w-1/3 px-4 flex flex-col">
                            <ServicesCard
                                title="Рекомендації"
                                description="Усні та письмові рекомендації учасникам освітнього курсу при працевлаштуванні на позиції за рекламними спеціальностями" image="/media/veterans/veterans_3.jpg" />
                        </Link>

                        {/* Карточка услуги */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VeteransSection;
