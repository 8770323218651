import { Link } from "react-router-dom";
import { Card } from "../../components/BusinessPracticesSlider";
import JustTextBlock from "../../components/JustTextBlock";
import Main from '../../components/Main';
import PhotoTextBlock from "../../components/elements/PhotoTextBlock";
import { useLanguage } from "../../../contexts/LanguageContext";
import articlesData from '../../../news/news.json';
import { useEffect, useState } from "react";


const BusinessPractices = () => {
    const [article, setArticle] = useState(null);
    const { language } = useLanguage();
    useEffect(() => {
        // Найти статью по slug
        const foundArticle = articlesData.articles;
        setArticle(foundArticle);
    }, []);

    return (
        <div className="business-practices">
                <Main
                    title="Veterans UP"
                    subtitle="Об’єднуємо досвід корпоративного сектору"
                    backgroundImage="/media/business-practices.jpg"
                />
            <JustTextBlock
                title='Практики в бізнесі'
                content={`<p className="text-[20px] font-medium text-[#262626] mb-6 leading-[30px]">
                Ми прагнемо, щоб ветеранські проєкти стали нормою в бізнесі – від маленьких крафтових підприємств до великих індустріальних парків, а поодинокі ініціативи перетворилися
в екосистему, інтегровану в загальнонаціональну політику зайнятості громадян.  
                </p>`} />

            <PhotoTextBlock
                img={'/media/business-practices-2.jpg'}
                title='Обмін досвідом'
                content={`<p className="text-base font-medium text-[#262626] mb-6">
                Якщо ваша компанія впроваджує ветеранські ініціативи, пов’язані з освітою, профорієнтацією, працевлаштуванням, залиште про це інформацію на нашій платформі.
                </p>`} />

            <div className="bg-[#e8e8e8] my-12 py-12">
                <div className="container mx-auto max-w-[1180px]">
                    <div className="flex flex-wrap items-center justify-center">
                        <div className="w-full text-center mb-8">
                            <Link to="/add-case" className="flex gap-2 bg-[#00c9cf] font-bold text-white py-4 px-10 mx-auto hover:bg-opacity-80 duration-200 w-fit" >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M5.478 5.559A1.5 1.5 0 0 1 6.912 4.5H9A.75.75 0 0 0 9 3H6.912a3 3 0 0 0-2.868 2.118l-2.411 7.838a3 3 0 0 0-.133.882V18a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0 0 17.088 3H15a.75.75 0 0 0 0 1.5h2.088a1.5 1.5 0 0 1 1.434 1.059l2.213 7.191H17.89a3 3 0 0 0-2.684 1.658l-.256.513a1.5 1.5 0 0 1-1.342.829h-3.218a1.5 1.5 0 0 1-1.342-.83l-.256-.512a3 3 0 0 0-2.684-1.658H3.265l2.213-7.191Z" clipRule="evenodd" />
                                    <path fillRule="evenodd" d="M12 2.25a.75.75 0 0 1 .75.75v6.44l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 0 1 1.06-1.06l1.72 1.72V3a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                                </svg>
                                Додати кейс
                            </Link>
                        </div>
                        {article && article.map((item, index) => <div className="w-full md:w-1/2 lg:w-1/3">
                            <Link to={`/news/${item.slug}`} key={item.slug}>
                                <Card
                                    image={item.image}
                                    title={item.title[language.toLowerCase()]}
                                    description={item.shortContent[language.toLowerCase()]}
                                    category={item.categories[language.toLowerCase()].join(', ')}
                                    count=""
                                    company=""
                                />
                            </Link>
                        </div>)}

                    </div>
                </div>
            </div>
        </div>
    );

}

export default BusinessPractices;