import React from 'react'
import Main from '../../components/Main'
import Modal from '../../components/Modal'
import parse from 'html-react-parser';

const successMessage = `<p>Дякуємо за ваш інтерес!</p>
<p>Інформація про ваш кейс була надіслана нашій команді</p>`;

const errorMessage = `<p>Щось пішло не так</p><p>Спробуйте ще раз</p>`;


const AddCase = () => {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [position, setPosition] = React.useState('');
    const [company, setCompany] = React.useState('');
    const [companyField, setCompanyField] = React.useState('');
    const [companyEmployees, setCompanyEmployees] = React.useState('');
    const [projectName, setProjectName] = React.useState('');
    const [projectDescription, setProjectDescription] = React.useState('');
    const [projectLink, setProjectLink] = React.useState('');
    const [files, setFiles] = React.useState([]);
    const [message, setMessage] = React.useState('');
    const [isLoad, setIsLoad] = React.useState(false);

    const isFormValid = () => {
        return name && email && position && company && companyField && companyEmployees && projectName && projectDescription && projectLink;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let totalSize = 0;

        // Тут можна додати логіку для обробки даних форми, наприклад, завантаження файлу
        //actions/formWIthFiles.php
        const formData = new FormData(e.target);

        files.forEach(file => {
            totalSize += file.size;
            formData.append('files[]', file);
        });

        const totalSizeMB = totalSize / 1024 / 1024;

        if (totalSizeMB > 20) {
            // Если общий размер файлов превышает 20МБ, предотвращаем отправку формы
            setMessage(`<p>Оберіть файли меншого розміру</p>`);
            setIsModalVisible(true);
            return false;
        }


        try {
            setIsLoad(true);
            const response = await fetch('/actions/formWIthFiles.php', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            if (response.ok) {
                setMessage(successMessage)
                // Очищення форми після успішного відправлення
                setName('');
                setEmail('');
                setPosition('');
                setCompany('');
                setCompanyField('');
                setCompanyEmployees('');
                setProjectName('');
                setProjectDescription('');
                setProjectLink('');
                setFiles('');
            }
        } catch (e) {
            setMessage(errorMessage);
            console.error(e);
        } finally {
            setIsLoad(false);
        }

        // Після завантаження файлу показуємо модальне вікно
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setMessage(''); // Очистити повідомлення
        setIsModalVisible(false); // Закрити модальне вікно
    };

    return (
        <div className="business-practices">
            <Main
                title="Veterans UP"
                subtitle="Об’єднуємо досвід корпоративного сектору"
                backgroundImage="/media/business-practices.jpg"
            />
            <div className='py-20 bg-[#e8e8e8]'>
                <div className="container mx-auto max-w-[1180px] px-4 lg:px-4">
                    <div className='w-2/3'>
                        <h2 className="text-[36px] font-bold text-[#262626] mb-6 leading-[30px]">Додати кейс</h2>
                        <p className='font-medium text-[20px] leading-[30px] text-[#262626]'>Усі поля є обов’язковими для заповнення, щоб надати ветеранам
                            максимально вичерпну інформацію</p>

                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <div>
                                <label htmlFor="name" className="block text-[#262626] text-[20px] font-medium mt-6">Ваше ім’я, прізвище</label>
                                <input required type="text" id="name" name='name' className="border border-[#8c8c8c] w-full mt-2"
                                    value={name} onChange={(e) => setName(e.target.value)} />
                            </div>

                            <div>
                                <label htmlFor="email" className="block text-[#262626] text-[20px] font-medium mt-6">Ваш e-mail <span className='text-[#9b9b9b]'>(для уточнення деталей при необхідності)</span></label>
                                <input required type="text" id="email" name='email' className="border border-[#8c8c8c] w-full mt-2"
                                    value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>

                            <div>
                                <label htmlFor="position" className="block text-[#262626] text-[20px] font-medium mt-6">Ваша посада</label>
                                <input required type="text" name='position' id="position" className="border border-[#8c8c8c] w-full mt-2"
                                    value={position} onChange={(e) => setPosition(e.target.value)} />
                            </div>

                            <div>
                                <label htmlFor="company" className="block text-[#262626] text-[20px] font-medium mt-6">Назва компанії, яка ініціювала ветеранський проєкт</label>
                                <input required type="text" name='company' id="company" className="border border-[#8c8c8c] w-full mt-2"
                                    value={company} onChange={(e) => setCompany(e.target.value)} />
                            </div>

                            <div>
                                <label htmlFor="companyField" className="block text-[#262626] text-[20px] font-medium mt-6">Сфера діяльності компанії</label>
                                <input required type="text" id="companyField" name='companyField' className="border border-[#8c8c8c] w-full mt-2"
                                    value={companyField} onChange={(e) => setCompanyField(e.target.value)} />
                            </div>

                            <div>
                                <label htmlFor="companyEmployees" className="block text-[#262626] text-[20px] font-medium mt-6">Приблизна кількість співробітників в компанії</label>
                                <input required type="text" name="companyEmployees" id="companyEmployees" className="border border-[#8c8c8c] w-full mt-2"
                                    value={companyEmployees} onChange={(e) => setCompanyEmployees(e.target.value)} />
                            </div>

                            <div>
                                <label htmlFor="projectName" className="block text-[#262626] text-[20px] font-medium mt-6">Назва проєкту</label>
                                <input required name='projectName' type="text" id="projectName" className="border border-[#8c8c8c] w-full mt-2"
                                    value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                            </div>

                            <div>
                                <label htmlFor="projectDescription" className="block text-[#262626] text-[20px] font-medium mt-6">Короткий опис проєкту <span className='text-[#9b9b9b]'>(1500 знаків максимум)</span></label>
                                <textarea id="projectDescription" className="border border-[#8c8c8c] w-full mt-2 min-h-[200px]" name='projectDescription'
                                    value={projectDescription} onChange={(e) => setProjectDescription(e.target.value)}></textarea>
                            </div>

                            <div>
                                <label htmlFor="projectLink" className="block text-[#262626] text-[20px] font-medium mt-6">Лінк на розширену інформацію про проєкт<br />
                                    <span className='text-[#9b9b9b]'>(ваш сайт, пост в соціальних мережах)<br />
                                        або контакт особи, в якої можна дізнатися про деталі</span>
                                </label>
                                <input required type="text" name='projectLink' id="projectLink" className="border border-[#8c8c8c] w-full mt-2"
                                    value={projectLink} onChange={(e) => setProjectLink(e.target.value)} />
                            </div>
                            {/* Add file input */}
                            <div>
                                <label htmlFor="addFile" className="block text-[#00cacf] text-[20px] font-medium mt-6 cursor-pointer"><div className='inline-flex gap-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-[#00cacf]">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                    </svg>
                                    <span className=' border-b  border-[#00cacf]'>Прикріпити додаткові файли</span>
                                </div>
                                    <br />
                                    <span className='pl-8 text-[#9b9b9b] block'>(логотип компанії/ логотип проєкту<br />
                                        / key visual проєкту)</span>
                                    <input type="file" id="addFile" className="hidden" name="attached[]" multiple onChange={
                                        // set multiple files
                                        (e) => setFiles(prev => {
                                            return [...prev, ...Array.from(e.target.files)]
                                        })
                                    } />
                                </label>
                                {/* display files */}
                                <div className='mt-4'>
                                    {files.length > 0 && <ul>
                                        {Array.from(files).map((file, index) => (
                                            <li key={index} className='flex gap-2 items-center'>{file.name}
                                                {/* remov btn */}
                                                <button type='button' onClick={() => {
                                                    const newFiles = [...files];
                                                    newFiles.splice(index, 1);
                                                    setFiles(newFiles);
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </li>
                                        ))}
                                    </ul>}
                                </div>
                            </div>

                            <button type='submit'
                                className={`flex gap-2 bg-[#00c9cf] font-bold text-white py-4 px-10 mt-10 hover:bg-opacity-80 duration-200 ${(!isFormValid() || isLoad) && 'opacity-50 cursor-not-allowed'}`}
                                disabled={!isFormValid() || isLoad}
                            >
                                Надіслати кейс
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <Modal isVisible={isModalVisible} onClose={handleCloseModal}>
                <div className="modal__content">
                    {
                        parse(message)
                    }
                </div>
            </Modal>
        </div>
    );
}

export default AddCase;